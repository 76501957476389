    enum SyncEntityType {
      BestSellers,
      First100,
      Category,
      Collection,
      Brand      
    }
  
  const syncEntitiesArray = [
    { title: "Best Sellers (Last 1K orders)", value: SyncEntityType.BestSellers },    
    // { title: "Random 100 Products", value: SyncEntityType.First100 },
    // { title: "Category", value: SyncEntityType.Category },
    { title: "Collection", value: SyncEntityType.Collection },    
    // { title: "Brand", value: SyncEntityType.Brand },    
  ];

  const googleSyncEntitiesArray = [
    // { title: "Best Sellers (Last 1K orders)", value: SyncEntityType.BestSellers },    
    { title: "Random 100 Products", value: SyncEntityType.First100 },
    { title: "Category", value: SyncEntityType.Category },
    // { title: "Collection", value: SyncEntityType.Collection },    
    { title: "Brand", value: SyncEntityType.Brand },    
  ];
  
  export {
    SyncEntityType,
    syncEntitiesArray,
    googleSyncEntitiesArray
  }
  